/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable max-len */
/* eslint-disable global-require */
import React from 'react';
// import { Typography } from 'antd';
// import BorderButton from './BorderButton';
import { isMobile, useMobileOrientation } from 'react-device-detect';

import ReactGA from 'react-ga4';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const arrowNext = require('../../img/bannerCarouselImgs/arrowNext.png');
const arrowPrev = require('../../img/bannerCarouselImgs/arrowPrev.png');

// const VirtualBoothBanner = require('../../img/bannerCarouselImgs/VirtualBoothBanner.png');
// const VirtualBoothBannerMobile = require('../../img/bannerCarouselImgs/VirtualBoothBannerMobile.png');

const VideoBanner = require('../../img/bannerCarouselImgs/VideoBanner.png');
const VideoBannerMobile = require('../../img/bannerCarouselImgs/VideoBannerMobile.png');

const BrainGameBanner = require('../../img/bannerCarouselImgs/BrainGameBanner.png');
const BrainGameBannerMobile = require('../../img/bannerCarouselImgs/BrainGameBannerMobile.png');

const braineryBanner = require('../../img/bannerCarouselImgs/braineryBanner.png');
const braineryBannerMobile = require('../../img/bannerCarouselImgs/braineryBannerMobile.png');

// const BroadcastBanner = require('../../img/bannerCarouselImgs/BroadcastBannerMarch2023.png');
// const BroadcastBannerMobile = require('../../img/bannerCarouselImgs/BroadcastBannerMarch2023Mobile.png');

// const BroadcastBanner = require('../../img/bannerCarouselImgs/BroadcastBannerNovember2023.png');
// const BroadcastBannerMobile = require('../../img/bannerCarouselImgs/BroadcastBannerNovember2023Mobile.png');

// const May22Banner = require('../../img/bannerCarouselImgs/MeetingBanner052223.png');
// const May22BannerMobile = require('../../img/bannerCarouselImgs/MeetingBanner052223Mobile.png');
// const May22ICS = require('../../static/event052223.ics');

// const June14Banner = require('../../img/bannerCarouselImgs/MeetingBanner061423.png');
// const June14BannerMobile = require('../../img/bannerCarouselImgs/MeetingBanner061423Mobile.png');

// const Nov9Banner = require('../../img/bannerCarouselImgs/Nov9Banner.png');
// const Nov9BannerMobile = require('../../img/bannerCarouselImgs/Nov9BannerMobile.png');

// const meetingSlideBackgroundImg = require('../../img/bannerCarouselImgs/MeetingSlide/background.png');
// const meetingDataImg = require('../../img/bannerCarouselImgs/MeetingSlide/December2ProgramBanner.png');
// const calendarButtonImg = require('../../img/bannerCarouselImgs/MeetingSlide/calendarButton.png');
// const agendaButtonImg = require('../../img/bannerCarouselImgs/MeetingSlide/agendaButton.png');

const arrowStyles = {
  position: 'absolute',
  top: 0,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  width: '3%',
  padding: 5,
  height: '100%',
  cursor: 'pointer'
};

const ITCBannerCarousel = () => {
  const [carouselImages, setCarouselImages] = React.useState([]);

  const mobileOrientation = useMobileOrientation();

  React.useEffect(() => {
    getCarouselImages();
  }, [mobileOrientation]); // eslint-disable-line

  const isMobileVertical = isMobile && mobileOrientation.isPortrait;

  const getCarouselImages = () => {
    // console.log('isMobileVertical', isMobileVertical);
    setCarouselImages([
      // {
      //   img: isMobileVertical ? BroadcastBannerMobile : BroadcastBanner,
      //   title: 'Broadcast Event March 2023',
      //   link: 'https://www.bpdbroadcast.com/',
      //   target: '_blank'
      // },
      // {
      //   img: isMobileVertical ? VirtualBoothBannerMobile : VirtualBoothBanner,
      //   title: 'ITCI Virtual Booth',
      //   link: 'https://www.itcibooth.com/',
      //   target: '_blank'
      // },
      // {
      //   img: isMobileVertical ? June14BannerMobile : June14Banner,
      //   title: 'MedTalk with Dr Roger McIntyre',
      //   link: 'https://www.bpdbroadcast.com',
      //   target: '_blank'
      // },
      {
        img: isMobileVertical ? braineryBannerMobile : braineryBanner,
        title: 'Brainery',
        link: 'http://www.intracellularbrainery.com/',
        target: '_blank'
      },
      {
        img: isMobileVertical ? VideoBannerMobile : VideoBanner,
        title: 'ITCI Treatment Perspectives',
        link: 'https://www.treatmentperspectives.com/bpd/IntraCellular',
        target: '_blank'
      },
      {
        img: isMobileVertical ? BrainGameBannerMobile : BrainGameBanner,
        title: 'Brain Game',
        link: '/game',
        target: '_self'
      }
      // {
      //   img: isMobileVertical ? BroadcastBannerMobile : BroadcastBanner,
      //   title: 'Broadcast Event November 2023',
      //   link: 'https://www.bpdbroadcast.com/',
      //   target: '_blank'
      // }
      // {
      //   html: (index) => {
      //     let icsTop = '26%';
      //     let icsRight = '7%';
      //     let icsWidth = '20%';
      //     let icsHeight = '20%';
      //     let agendaTop = '50%';
      //     let agendaRight = '7%';
      //     let agendaWidth = '20%';
      //     let agendaHeight = '20%';
      //     let bannerImg = May22Banner;

      //     if (isMobileVertical) {
      //       icsTop = '76%';
      //       icsRight = '55%';
      //       icsWidth = '38%';
      //       icsHeight = '20%';
      //       agendaTop = '76%';
      //       agendaRight = '7%';
      //       agendaWidth = '38%';
      //       agendaHeight = '20%';
      //       bannerImg = May22BannerMobile;
      //     }
      //     return (
      //       <div key={index} style={{ height: '100%', display: 'relative' }}>
      //         <img src={bannerImg} alt={'May 22 Meeting'} style={{ height: '100%' }} />
      //         <a
      //           href={May22ICS}
      //           target="_blank"
      //           rel="noopener noreferrer"
      //           style={{
      //             position: 'absolute',
      //             top: icsTop,
      //             right: icsRight,
      //             width: icsWidth,
      //             height: icsHeight
      //           }}
      //         />
      //         <a
      //           href={
      //             'https://www.psychiatry.org/psychiatrists/meetings/annual-meeting/schedule-at-a-glance'
      //           }
      //           target={'_blank'}
      //           rel="noopener noreferrer"
      //           style={{
      //             position: 'absolute',
      //             top: agendaTop,
      //             right: agendaRight,
      //             width: agendaWidth,
      //             height: agendaHeight
      //           }}
      //         />
      //       </div>
      //     );
      //   }
      // }
    ]);
  };

  const renderCarouselImgs = () => {
    return carouselImages.map((item, index) => {
      if (item.html) {
        return item.html(index);
      }
      return (
        <div
          key={index}
          onClick={() => {
            ReactGA.event({
              category: 'Carousel Banner Click',
              action: item.title
            });
            window.open(item.link, item.target);
          }}
          style={{ cursor: 'pointer', height: '100%' }}
          role={'button'}
          tabIndex={index}
        >
          <img src={item.img} alt={item.title} style={{ height: '100%' }} />
        </div>
      );
    });
  };

  if (!carouselImages.length) {
    return '';
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        renderArrowPrev={(onClickHandler, hasPrev, label) => {
          if (hasPrev) {
            return (
              <div
                onClick={onClickHandler}
                style={{ ...arrowStyles, left: 5 }}
                role={'button'}
                tabIndex={0}
              >
                <img src={arrowPrev} alt={label} />
              </div>
            );
          }
        }}
        renderArrowNext={(onClickHandler, hasNext, label) => {
          // console.log('hasNext', hasNext);
          if (hasNext) {
            return (
              <div
                onClick={onClickHandler}
                style={{ ...arrowStyles, right: 5 }}
                role={'button'}
                tabIndex={0}
              >
                <img src={arrowNext} alt={label} />
              </div>
            );
          }
        }}
      >
        {renderCarouselImgs()}
      </Carousel>
    </div>
  );
};

export default ITCBannerCarousel;
